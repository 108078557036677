import Header from './Header';
import Search from './Search';
import Footer from './Footer';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NotFound = () => (
  <section className="text-gray-600 body-font">
    <div className="container px-5 mx-auto">
      <p className="mx-auto lg:w-3/5">お探しのページが見つかりません。お手数ですが再度お試しください。</p>
    </div>
  </section>
);

function App() {
  return (
    <>
      <Header/>
      <Router>
        <Search/>
      </Router>
      <Footer/>
    </>
  );
}

export default App;