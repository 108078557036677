import React from 'react';
import { withRouter } from "react-router-dom";
// import algoliasearch from 'algoliasearch';
// import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// const searchClient = algoliasearch(
//   'FLFP8JL5RI', //algolia application ID
//   `f8fde6437f6706723266a2eecfce0b5f` //algolia search only key
// );
// const indexName = 'kousoku';
// const index = searchClient.initIndex(indexName);

class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ""};
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  // searchSubmit(event) {
  //   event.preventDefault();
  //   index.search(this.state.value).then(({ hits }) => {
  //     console.log(hits);
  //   });
  // }

  handleSearchTextChange(e) {
    this.props.onSearchTextChange(e.target.value);
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    // Searchコンポーネントにsubmitを知らせたい。
    this.props.onSearchSubmit();
    //個別ページから検索した場合は、トップページ（検索結果表示）へ遷移
    if (this.props.location.pathname !== '/') {
      this.props.history.push('/')
    }
  }

  render() {
    const searchText = this.props.searchText;

    return(
      <>
        <section className="text-gray-600 body-font">
          {/* <p className="max-w-3xl mx-auto leading-normal my-6 font-bold text-base lg:text-xl text-left lg:text-center">
            高校の校則、規則を全文検索。自分スタイルの高校選び、はじめませんか。
          </p> */}
          <div className="container mx-auto flex flex-col px-5 py-5 justify-center items-center">
            <p className="max-w-3xl mx-auto leading-normal mb-6 font-bold text-base lg:text-xl text-left lg:text-center">
              高校の校則、規則を全文検索。自分スタイルの高校選び、はじめませんか。
            </p>
            <div className="w-full md:w-2/3 flex flex-col items-center text-center">
              <form 
                onSubmit={this.handleSearchSubmit}
                className="flex w-full justify-center items-end"
              >
                <div className="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-3/4 text-left">
                  <input type="text" id="search" name="search" value={searchText} onChange={this.handleSearchTextChange}
                    placeholder="(例)神奈川県、東大阪市、○○高等学校、下着、地毛、異装"
                    className="w-full bg-gray-100 bg-opacity-50 rounded focus:ring-2 focus:ring-indigo-200 focus:bg-transparent border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <button type="submit" className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">検索</button>
              </form>
              <p className="text-sm mt-2 text-gray-500 mb-8">対応エリア：神奈川県、大阪府の公立高校。通信制除く。</p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(SearchBar);