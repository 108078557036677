import React, { useEffect, useState, useRef } from 'react';
import { db } from './firebase';
import { prefCodeList, cityCodeList } from './Global';
import { useHistory, useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";


function List() {
  // {prefCode = null, cityCode = null}
  const [areaCode, setAreaCode] =  useState({
    pref: null,
    city: null
  });
  const [schoolName, setSchoolName] = useState(null);
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // const history = useHistory();
  const location = useLocation();
  const locationState = location.state;
  // console.log(locationState);

  // const history = useHistory();

  const areaCodeRef = useRef(null);
  const schoolNameRef = useRef(null);

  function PrefListItem(props) {
    return (
      <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3">
        <button onClick={() => {
          setAreaCode({pref: props.prefCode, city: null});
          setIsLoading(true);
          window.history.pushState(null, null, null);
          // console.log("押された");
        }}
          className="block w-full h-full p-4">{prefCodeList[props.prefCode]}</button>
      </li>
    )
  }
  function PrefList() {
    const prefListItems = listData.map(pref =>
      <PrefListItem key={pref.prefCode} prefCode={pref.prefCode} />
    );
    return <>{prefListItems}</>
  }

  function CityListItem(props) {
    return(
      <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3">
        <button onClick={() => {
          setAreaCode({...areaCode, city: props.cityCode});
          setIsLoading(true);
        }}
          className="block w-full h-full p-4">{cityCodeList[props.cityCode]}</button>
      </li>
    );
  }
  function CityList() {
    // console.log(listData);
    const cityListItems = listData.map(city =>
      <CityListItem key={city.cityCode} cityCode={city.cityCode} />
    );
    return <>{cityListItems}</>;
  }

  const BackToPrefs = () => {
    return <a onClick={(e) => {setAreaCode({pref: null, city:null}); setIsLoading(true); e.preventDefault();}}
              className="flex mb-5 justify-center underline text-indigo-500 block" href="#">←都道府県一覧</a>;
  }

  function SchoolListItem(props) {
    return (
      <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3">
        <button onClick={() => {
          setSchoolName(props.schoolName);
          setIsLoading(true);
        }}
          className="block w-full h-full p-4">{props.schoolName}</button>
      </li>
    );
  }
  function SchoolList() {
    const schoolListItems = listData.map(school =>
      <SchoolListItem key={school.schoolCode} schoolName={school.schoolName} />
    );
    return <>{schoolListItems}</>;
  }

  const BackToCities = () => {
    return <a onClick={(e) => {setAreaCode({...areaCode, city:null}); setIsLoading(true); e.preventDefault();}}
              className="flex mb-5 justify-center underline text-indigo-500 block" href="#">←市町村一覧</a>;
  }

  // function RuleListItem(props) {
  //   // const history = useHistory();
  //   return (
  //     <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3">
  //       <button onClick={() => { props.history.push(`/rule/${props.documentId}`, {areaCode: areaCode, schoolName: props.schoolName}) }}
  //               className="block w-full h-full p-4">{props.schoolName} {props.ruleName}
  //       </button>
  //     </li>
  //   );
  // }

  function RuleList() {
    // console.log(listData);
    const history = useHistory();
    // const ruleListItems = listData.map(rule =>
    //   <RuleListItem key={rule.documentId} schoolName={rule.schoolName} ruleName={rule.ruleName} history={history} />
    // );
    // return <>{ruleListItems}</>;

    // let isRule = (listData.count > 0) ? true : false;
    let isRule = listData.length > 0;
    const ruleItems = listData.map(rule =>
      <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3" key={rule.documentId}>
        <button onClick={() => { history.push(`/rule/${rule.documentId}`, {areaCode: areaCode, schoolName: rule.schoolName}) }} className="block w-full h-full p-4">{rule.schoolName} {rule.ruleName}</button>
      </li>);
    const noRules = <div className="container mx-auto px-5 py-5 items-center">{schoolName}の校則データは、まだ入手できていません。提供していただける方を募集中です。ぜひ<a href="https://formok.com/f/skrj6nzt" className="underline text-indigo-500">校則校則情報ご提供フォーム</a>よりご連絡ください。</div>;
    return( <>{isRule ? ruleItems : noRules }</> );
  }

  const BackToSchools = () => {
    return <a onClick={(e) => {setSchoolName(null); setIsLoading(true); e.preventDefault();}}
              className="flex mb-5 justify-center underline text-indigo-500 block" href="#">←学校一覧</a>;
  }

  const listViewData = (areaCode) => {
    if (isLoading) {
      // console.dir("Now Loading.");
      // console.dir(areaCode);
      return(
        <div className = "fixed top-1/2 left-1/2">
          <Loader
            type="TailSpin"
            color="#E0E7FF"
            height={80}
            width={80}
            timeout={3000} //3 secs
          />
        </div>
      );
    } else {
      // console.log("Loading is done.");
      if (areaCode.pref === null && areaCode.city === null) {
        return (
          <ul className="flex flex-wrap -m-2 justify-center">
            <PrefList />
          </ul>
        );
      } else if (areaCode.pref != null && areaCode.city === null) {
        return (
          <>
            <BackToPrefs />
            <ul className="flex flex-wrap -m-2 justify-center">
              <CityList  />
            </ul>
          </>
        );

      } else if (areaCode.city != null && schoolName === null) {
        return (
          <>
            <BackToCities />
            <ul className="flex flex-wrap -m-2 justify-center">
              <SchoolList />
            </ul>
          </>
        );
      } else if (schoolName != null) {
        return (
          <>
            <BackToSchools />
            <ul className="flex flex-wrap -m-2 justify-center">
              <RuleList />
            </ul>
          </>
        );
      } else {
        return null;
      }
    }
  }
  useEffect(() => {
    // console.log("UseEfeect発動");
    setIsLoading(true);
    if(locationState) {
      setAreaCode({pref: locationState.areaCode.pref, city:locationState.areaCode.city});
      setSchoolName(locationState.schoolName);
    }
  },[])

  // useEffect(() => {
  //   // window.history.pushState(null, null, null);
  //   // console.log("ここどこ");
  //   // console.log("履歴のuseEffect発動");
  //   // areaCodeRef.current = areaCode;
  //   // schoolNameRef.current = schoolName;
  //   window.addEventListener('popstate', overridePopstate, false);
  //   return () => window.removeEventListener('popstate', overridePopstate, false);
  //   },[])

  // useEffect(() => {
  //   console.log("refセット発動");
  //   // console.log(areaCode);
  //   // console.log(areaCodeRef.current);
  //   areaCodeRef.current = areaCode;
  //   schoolNameRef.current = schoolName;
  // },[areaCode, schoolName])

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    if(areaCode.pref === null && areaCode.city === null) {
      const order = "prefCode";
      async function getPrefListFromDB () {
        try {
          const snapshot = await db.collection("prefExistsSchools").orderBy(order).get();
          const listArray = snapshot.docs.map(doc => {
            return doc.data();
          })
          setListData(listArray);
          setIsLoading(false);
          // console.log(listData);
        } catch(error) {
          setIsError(true);
        }
      }

      void getPrefListFromDB();

    } else if(areaCode.pref != null && areaCode.city === null) {
      const order = "cityCode";
      const roundedPrefCode = Math.round(areaCode.pref / 10000) * 10000;
      async function getCityListFromDB () {
        try {
          const snapshot = await db.collection("cityExistsSchools")
            .where("cityCode", ">=", roundedPrefCode)
            .where("cityCode", "<", roundedPrefCode+10000)
            .orderBy(order)
            .get();
          const listArray = snapshot.docs.map(doc => {
            return doc.data();
          })
          setListData(listArray);
          setIsLoading(false);
          // console.log(listData);
        } catch(error) {
          setIsError(true);
        }
      }

      void getCityListFromDB();
    } else if (areaCode.city != null && schoolName === null) {
      const order = "schoolCode";
      async function getSchoolListFromDB() {
        try {
          const snapshot = await db.collection("highschools").where("cityCode", "==", areaCode.city).orderBy(order).get();
          const listArray = snapshot.docs.map(doc => {
            return doc.data();
          })
          setListData(listArray);
          setIsLoading(false);
          // console.log(listData);
        } catch (error) {
          setIsError(true);
          // console.log(error);
        }
      }

      void getSchoolListFromDB();
    } else if (schoolName != null) {
      const order = "ruleName";
      async function getRuleListFromDB() {
        try {
          const snapshot = await db.collection("kousoku").where("schoolName","==",schoolName).orderBy(order).get();
          const listArray = snapshot.docs.map(doc => {
            let ruleData = doc.data();
            ruleData.documentId = doc.id;
            return ruleData;
          })
          setListData(listArray);
          setIsLoading(false);
          // console.log(listData);
        } catch (error) {
          setIsError(true);
          // console.log(error);
        }
      }

      void getRuleListFromDB();
    }

    // console.log(prefCode);
    // console.log(cityCode);
    // console.log("areaCode.pref: "+areaCode.pref);
    // console.log("areaCode.city: "+areaCode.city);
    // console.log("listMode: "+listMode);

    // if (areaCode.pref === null && areaCode.city === null) {
    //   //都道府県
    //   console.log("ここは都道府県を表示したい");
    //   getListFromDB ("prefExistsSchools", areaCode.pref, areaCode.city, "prefCode");
    // } else if (areaCode.pref != null && areaCode.city === null) {
    //   //市町村
    //   console.log("市町村表示したい");
    //   getListFromDB ("cityExistsSchools", areaCode.pref, areaCode.city, "cityCode");
    // } else if (areaCode.city != null) {
    //   //学校
    //   console.log("学校表示したい");
    //   getListFromDB ("highschools", areaCode.pref, areaCode.city, "schoolCode");
    // } else {
    //   //なにかへん
    //   console.log("なにかへん");

    // }
    return () => {
      isMounted = false;
    }

  }, [areaCode, schoolName]);

  const overridePopstate = () => {
    // console.log("ぽっぷ");
    // console.log(areaCode);
    // console.log("pref"+areaCodeRef.current.pref);
    // console.log("city"+areaCodeRef.current.city);
    // window.history.pushState(null, null, null);

    if (areaCodeRef.current.pref != null && areaCodeRef.current.city === null) {
      // window.history.pushState(null, null, null);
      // console.log("overridePopstate 都道府県に戻る");
      setAreaCode({pref: null, city: null});
    } else if (areaCodeRef.current.city != null && schoolNameRef.current === null) {
      // console.log("overridePopstate 市町村に戻る");
      // console.log(areaCode);
      // console.log("prefRef: "+areaCodeRef.current.pref);
      // console.log("cityRef: "+areaCodeRef.current.city);
      window.history.pushState(null, null, null);
      setAreaCode({...areaCode, city:null});

    } else if (schoolNameRef.current != null) {
      // console.log(schoolNameRef.current);
      window.history.pushState(null, null, null);
      setSchoolName(null);
      // console.log("overridePopstate 学校一覧に戻る");

    } else {
      // console.log("それ以外");
      window.history.back();
    }
  }

  return (
    <>
      {listViewData(areaCode)}
      {/*{*/}
      {/*  (() => {*/}
      {/*    if(areaCode.pref === null && areaCode.city === null) {*/}
      {/*      return(*/}
      {/*        {listData.map((pref) => */}
      {/*          <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3" key={pref.prefCode}>*/}
      {/*            <button onClick={() => setAreaCode({pref: pref.prefCode, city: null})} className="block w-full h-full p-4">{prefCodeList[pref.prefCode]}</button>*/}
      {/*          </li>*/}
      {/*        )}*/}
      {/*      );*/}
      {/*    } else if(areaCode.pref != null && areaCode.city === null) {*/}
      {/*      return(*/}
      {/*        {listData.map((city) => */}
      {/*          <li className="lg:w-1/4 md:w-1/3 w-full border-gray-200 border rounded-lg m-3" key={city.cityCode}>*/}
      {/*            <button onClick={() => setAreaCode({...areaCode, city: city.cityCode})} className="block w-full h-full p-4">{city.cityCode}</button>*/}
      {/*          </li>*/}
      {/*        )}*/}
      {/*      );*/}
      {/*    }*/}
      {/*  })()*/}
      {/*}*/}
    </>
  );
}

export default List;