
function Donation() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto xl:w-3/5">
        {/* <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">寄付者一覧</h2>
        <p className="mt-2"><a href="/Donors" className="underline text-indigo-500">寄付者一覧</a></p> */}

        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mt-10">Buy Me a Coffeeによる寄附について</h2>
        <p className="mt-2">校則DBの開発・運営費用にあてるため、<a href="https://www.buymeacoffee.com/kosokuDB" className="underline text-indigo-500">Buy Me a Coffee</a>による寄附を受け付けます。</p>
        <p className="mt-4">↓のボタンから、クレジットカードで、簡単に、<span className="underline"><b>単発</b>または<b>継続的</b>なご支援</span>をいただくことができます。</p>
        
        <div className="w-100 xl:w-1/4 sm:w-2/5 mt-3">
          <a href="https://www.buymeacoffee.com/kosokuDB" target="_blank">
            <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className="hover:opacity-30" />
          </a>
        </div>
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mt-10">Amazonギフトによる寄附について</h2>

        <p className="mt-2">校則DBの開発・運営費用にあてるため、Amazonギフトによる寄附を受け付けます。</p>

        <h3 className="text-xl font-bold leading-5 text-gray-900 sm:text-2xl sm:truncate mt-5">寄付金額</h3>
        <p>15円〜</p>

        <h3 className="text-xl font-bold leading-5 text-gray-900 sm:text-2xl sm:truncate mt-5">寄付方法</h3>
        <ol className="list-decimal ml-8">
          <li className="mb-2"><a href="https://amzn.to/3Fb1y1I" className="underline text-indigo-500">Amazonギフト券（Eメールタイプ）商品サイト</a>をクリック<br />
          ※Amazonアソシエイトリンクです。アソシエイトとして適格販売し得た収入についても、当DBの開発・運営費用にあてております。</li>
          <li className="mb-2"><b>お好きな金額</b>を指定し、受取人に<b>amazon-gift@kosoku.info</b>を入力してご購入</li>
          <li>（オプション）下の寄附特典ご希望の方は、掲載されたい情報を、メッセージ欄にご記入ください。記載がなければ、希望されないものとして扱います。</li>
        </ol>

        <h3 className="text-xl font-bold leading-5 text-gray-900 sm:text-2xl sm:truncate mt-5">寄附特典と条件</h3>
        <p>1,000円以上のご寄付をいただいた個人または3,000円以上のご寄付をいただいた法人は、ご寄付いただいた金額と寄付者のお名前等をサイトの寄付者一覧又はその下位ページに掲載いたします。</p>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-3">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              掲載いただける情報
            </h3>
            <p className="mt-1 pl-4 max-w-2xl text-sm text-gray-500">
              掲載例：<br />
              2022/01/08<br />
              3000円　有限会社リトルグリーブ（滋賀県大津市）Webシステムの開発を行う会社です！  <a href="https://grebe.jp">https://grebe.jp</a>
            </p>
          </div>
          <div className="border border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  お名前または法人名
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  必須。<br />仮名・芸名・職務上の氏名その可能。<br />
                  ただし、名誉毀損・侮辱・差別的言辞・性的表現その他掲載が不適当と考えられる表現にあたる場合には省略する場合があり、この場合もご寄付の返金はいたしません。
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  ご住所
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  全部又は一部の省略可能。
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  ご自身の職業に関する宣伝や推しなどポジティブな方向での一言（全角140文字以内）
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  省略可能
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  URL
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  省略可能。<br />ただし、リンク先が名誉毀損・侮辱・差別的言辞・性的表現その他リンクの貼り付けが不適当と考えられる場合にはURLを掲載する場合があり、この場合もご寄付の返金はいたしません。
                </dd>
              </div>
            </dl>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Donation;