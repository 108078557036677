export const prefCodeList = {
  "010006": "北海道", 
  "020001": "青森県", 
  "030007": "岩手県", 
  "040002": "宮城県", 
  "050008": "秋田県", 
  "060003": "山形県", 
  "070009": "福島県", 
  "080004": "茨城県", 
  "090000": "栃木県", 
  "100005": "群馬県", 
  "110001": "埼玉県", 
  "120006": "千葉県", 
  "130001": "東京都", 
  "140007": "神奈川県", 
  "150002": "新潟県", 
  "160008": "富山県", 
  "170003": "石川県", 
  "180009": "福井県", 
  "190004": "山梨県", 
  "200000": "長野県", 
  "210005": "岐阜県", 
  "220001": "静岡県", 
  "230006": "愛知県", 
  "240001": "三重県", 
  "250007": "滋賀県", 
  "260002": "京都府", 
  "270008": "大阪府", 
  "280003": "兵庫県", 
  "290009": "奈良県", 
  "300004": "和歌山県", 
  "310000": "鳥取県", 
  "320005": "島根県", 
  "330001": "岡山県", 
  "340006": "広島県", 
  "350001": "山口県", 
  "360007": "徳島県", 
  "370002": "香川県", 
  "380008": "愛媛県", 
  "390003": "高知県", 
  "400009": "福岡県", 
  "410004": "佐賀県", 
  "420000": "長崎県", 
  "430005": "熊本県", 
  "440001": "大分県", 
  "450006": "宮崎県", 
  "460001": "鹿児島県", 
  "470007": "沖縄県"
};

export const cityCodeList = {
  "011002": "札幌市",
  "012025": "函館市",
  "012033": "小樽市",
  "012041": "旭川市",
  "012050": "室蘭市",
  "012068": "釧路市",
  "012076": "帯広市",
  "012084": "北見市",
  "012092": "夕張市",
  "012106": "岩見沢市",
  "012114": "網走市",
  "012122": "留萌市",
  "012131": "苫小牧市",
  "012149": "稚内市",
  "012157": "美唄市",
  "012165": "芦別市",
  "012173": "江別市",
  "012181": "赤平市",
  "012190": "紋別市",
  "012203": "士別市",
  "012211": "名寄市",
  "012220": "三笠市",
  "012238": "根室市",
  "012246": "千歳市",
  "012254": "滝川市",
  "012262": "砂川市",
  "012271": "歌志内市",
  "012289": "深川市",
  "012297": "富良野市",
  "012301": "登別市",
  "012319": "恵庭市",
  "012335": "伊達市",
  "012343": "北広島市",
  "012351": "石狩市",
  "012360": "北斗市",
  "013030": "当別町",
  "013048": "新篠津村",
  "013315": "松前町",
  "013323": "福島町",
  "013331": "知内町",
  "013340": "木古内町",
  "013374": "七飯町",
  "013439": "鹿部町",
  "013455": "森町",
  "013463": "八雲町",
  "013471": "長万部町",
  "013617": "江差町",
  "013625": "上ノ国町",
  "013633": "厚沢部町",
  "013641": "乙部町",
  "013676": "奥尻町",
  "013706": "今金町",
  "013714": "せたな町",
  "013919": "島牧村",
  "013927": "寿都町",
  "013935": "黒松内町",
  "013943": "蘭越町",
  "013951": "ニセコ町",
  "013960": "真狩村",
  "013978": "留寿都村",
  "013986": "喜茂別町",
  "013994": "京極町",
  "014001": "倶知安町",
  "014010": "共和町",
  "014028": "岩内町",
  "014036": "泊村",
  "014044": "神恵内村",
  "014052": "積丹町",
  "014061": "古平町",
  "014079": "仁木町",
  "014087": "余市町",
  "014095": "赤井川村",
  "014231": "南幌町",
  "014249": "奈井江町",
  "014257": "上砂川町",
  "014273": "由仁町",
  "014281": "長沼町",
  "014290": "栗山町",
  "014303": "月形町",
  "014311": "浦臼町",
  "014320": "新十津川町",
  "014338": "妹背牛町",
  "014346": "秩父別町",
  "014362": "雨竜町",
  "014371": "北竜町",
  "014389": "沼田町",
  "014524": "鷹栖町",
  "014532": "東神楽町",
  "014541": "当麻町",
  "014559": "比布町",
  "014567": "愛別町",
  "014575": "上川町",
  "014583": "東川町",
  "014591": "美瑛町",
  "014605": "上富良野町",
  "014613": "中富良野町",
  "014621": "南富良野町",
  "014630": "占冠村",
  "014648": "和寒町",
  "014656": "剣淵町",
  "014681": "下川町",
  "014699": "美深町",
  "014702": "音威子府村",
  "014711": "中川町",
  "014729": "幌加内町",
  "014818": "増毛町",
  "014826": "小平町",
  "014834": "苫前町",
  "014842": "羽幌町",
  "014851": "初山別村",
  "014869": "遠別町",
  "014877": "天塩町",
  "015113": "猿払村",
  "015121": "浜頓別町",
  "015130": "中頓別町",
  "015148": "枝幸町",
  "015164": "豊富町",
  "015172": "礼文町",
  "015181": "利尻町",
  "015199": "利尻富士町",
  "015202": "幌延町",
  "015431": "美幌町",
  "015440": "津別町",
  "015458": "斜里町",
  "015466": "清里町",
  "015474": "小清水町",
  "015491": "訓子府町",
  "015504": "置戸町",
  "015521": "佐呂間町",
  "015555": "遠軽町",
  "015598": "湧別町",
  "015601": "滝上町",
  "015610": "興部町",
  "015628": "西興部村",
  "015636": "雄武町",
  "015644": "大空町",
  "015717": "豊浦町",
  "015750": "壮瞥町",
  "015784": "白老町",
  "015814": "厚真町",
  "015849": "洞爺湖町",
  "015857": "安平町",
  "015865": "むかわ町",
  "016012": "日高町",
  "016021": "平取町",
  "016047": "新冠町",
  "016071": "浦河町",
  "016080": "様似町",
  "016098": "えりも町",
  "016101": "新ひだか町",
  "016314": "音更町",
  "016322": "士幌町",
  "016331": "上士幌町",
  "016349": "鹿追町",
  "016357": "新得町",
  "016365": "清水町",
  "016373": "芽室町",
  "016381": "中札内村",
  "016390": "更別村",
  "016411": "大樹町",
  "016420": "広尾町",
  "016438": "幕別町",
  "016446": "池田町",
  "016454": "豊頃町",
  "016462": "本別町",
  "016471": "足寄町",
  "016489": "陸別町",
  "016497": "浦幌町",
  "016616": "釧路町",
  "016624": "厚岸町",
  "016632": "浜中町",
  "016641": "標茶町",
  "016659": "弟子屈町",
  "016675": "鶴居村",
  "016683": "白糠町",
  "016918": "別海町",
  "016926": "中標津町",
  "016934": "標津町",
  "016942": "羅臼町",
  "016951": "色丹村",
  "016969": "泊村",
  "016977": "留夜別村",
  "016985": "留別村",
  "016993": "紗那村",
  "017001": "蘂取村",
  "022012": "青森市",
  "022021": "弘前市",
  "022039": "八戸市",
  "022047": "黒石市",
  "022055": "五所川原市",
  "022063": "十和田市",
  "022071": "三沢市",
  "022080": "むつ市",
  "022098": "つがる市",
  "022101": "平川市",
  "023019": "平内町",
  "023035": "今別町",
  "023043": "蓬田村",
  "023078": "外ヶ浜町",
  "023213": "鰺ヶ沢町",
  "023230": "深浦町",
  "023434": "西目屋村",
  "023612": "藤崎町",
  "023621": "大鰐町",
  "023671": "田舎館村",
  "023817": "板柳町",
  "023841": "鶴田町",
  "023876": "中泊町",
  "024015": "野辺地町",
  "024023": "七戸町",
  "024058": "六戸町",
  "024066": "横浜町",
  "024082": "東北町",
  "024112": "六ヶ所村",
  "024121": "おいらせ町",
  "024236": "大間町",
  "024244": "東通村",
  "024252": "風間浦村",
  "024261": "佐井村",
  "024414": "三戸町",
  "024422": "五戸町",
  "024431": "田子町",
  "024457": "南部町",
  "024465": "階上町",
  "024503": "新郷村",
  "032018": "盛岡市",
  "032026": "宮古市",
  "032034": "大船渡市",
  "032051": "花巻市",
  "032069": "北上市",
  "032077": "久慈市",
  "032085": "遠野市",
  "032093": "一関市",
  "032107": "陸前高田市",
  "032115": "釜石市",
  "032131": "二戸市",
  "032140": "八幡平市",
  "032158": "奥州市",
  "032166": "滝沢市",
  "033014": "雫石町",
  "033022": "葛巻町",
  "033031": "岩手町",
  "033219": "紫波町",
  "033227": "矢巾町",
  "033669": "西和賀町",
  "033812": "金ケ崎町",
  "034029": "平泉町",
  "034410": "住田町",
  "034614": "大槌町",
  "034827": "山田町",
  "034835": "岩泉町",
  "034843": "田野畑村",
  "034851": "普代村",
  "035017": "軽米町",
  "035033": "野田村",
  "035068": "九戸村",
  "035076": "洋野町",
  "035246": "一戸町",
  "041009": "仙台市",
  "042021": "石巻市",
  "042030": "塩竈市",
  "042056": "気仙沼市",
  "042064": "白石市",
  "042072": "名取市",
  "042081": "角田市",
  "042099": "多賀城市",
  "042111": "岩沼市",
  "042129": "登米市",
  "042137": "栗原市",
  "042145": "東松島市",
  "042153": "大崎市",
  "042161": "富谷市",
  "043010": "蔵王町",
  "043028": "七ヶ宿町",
  "043214": "大河原町",
  "043222": "村田町",
  "043231": "柴田町",
  "043249": "川崎町",
  "043419": "丸森町",
  "043613": "亘理町",
  "043621": "山元町",
  "044016": "松島町",
  "044041": "七ヶ浜町",
  "044067": "利府町",
  "044211": "大和町",
  "044229": "大郷町",
  "044245": "大衡村",
  "044440": "色麻町",
  "044458": "加美町",
  "045012": "涌谷町",
  "045055": "美里町",
  "045811": "女川町",
  "046060": "南三陸町",
  "052019": "秋田市",
  "052027": "能代市",
  "052035": "横手市",
  "052043": "大館市",
  "052060": "男鹿市",
  "052078": "湯沢市",
  "052094": "鹿角市",
  "052108": "由利本荘市",
  "052116": "潟上市",
  "052124": "大仙市",
  "052132": "北秋田市",
  "052141": "にかほ市",
  "052159": "仙北市",
  "053031": "小坂町",
  "053279": "上小阿仁村",
  "053465": "藤里町",
  "053481": "三種町",
  "053490": "八峰町",
  "053619": "五城目町",
  "053635": "八郎潟町",
  "053660": "井川町",
  "053686": "大潟村",
  "054348": "美郷町",
  "054631": "羽後町",
  "054640": "東成瀬村",
  "062014": "山形市",
  "062022": "米沢市",
  "062031": "鶴岡市",
  "062049": "酒田市",
  "062057": "新庄市",
  "062065": "寒河江市",
  "062073": "上山市",
  "062081": "村山市",
  "062090": "長井市",
  "062103": "天童市",
  "062111": "東根市",
  "062120": "尾花沢市",
  "062138": "南陽市",
  "063011": "山辺町",
  "063029": "中山町",
  "063215": "河北町",
  "063223": "西川町",
  "063231": "朝日町",
  "063240": "大江町",
  "063410": "大石田町",
  "063614": "金山町",
  "063622": "最上町",
  "063631": "舟形町",
  "063649": "真室川町",
  "063657": "大蔵村",
  "063665": "鮭川村",
  "063673": "戸沢村",
  "063819": "高畠町",
  "063827": "川西町",
  "064017": "小国町",
  "064025": "白鷹町",
  "064033": "飯豊町",
  "064262": "三川町",
  "064289": "庄内町",
  "064611": "遊佐町",
  "072010": "福島市",
  "072028": "会津若松市",
  "072036": "郡山市",
  "072044": "いわき市",
  "072052": "白河市",
  "072079": "須賀川市",
  "072087": "喜多方市",
  "072095": "相馬市",
  "072109": "二本松市",
  "072117": "田村市",
  "072125": "南相馬市",
  "072133": "伊達市",
  "072141": "本宮市",
  "073016": "桑折町",
  "073032": "国見町",
  "073083": "川俣町",
  "073229": "大玉村",
  "073423": "鏡石町",
  "073440": "天栄村",
  "073628": "下郷町",
  "073644": "檜枝岐村",
  "073679": "只見町",
  "073687": "南会津町",
  "074021": "北塩原村",
  "074055": "西会津町",
  "074071": "磐梯町",
  "074080": "猪苗代町",
  "074217": "会津坂下町",
  "074225": "湯川村",
  "074233": "柳津町",
  "074446": "三島町",
  "074454": "金山町",
  "074462": "昭和村",
  "074471": "会津美里町",
  "074616": "西郷村",
  "074641": "泉崎村",
  "074659": "中島村",
  "074667": "矢吹町",
  "074811": "棚倉町",
  "074829": "矢祭町",
  "074837": "塙町",
  "074845": "鮫川村",
  "075019": "石川町",
  "075027": "玉川村",
  "075035": "平田村",
  "075043": "浅川町",
  "075051": "古殿町",
  "075213": "三春町",
  "075221": "小野町",
  "075418": "広野町",
  "075426": "楢葉町",
  "075434": "富岡町",
  "075442": "川内村",
  "075451": "大熊町",
  "075469": "双葉町",
  "075477": "浪江町",
  "075485": "葛尾村",
  "075612": "新地町",
  "075647": "飯舘村",
  "082015": "水戸市",
  "082023": "日立市",
  "082031": "土浦市",
  "082040": "古河市",
  "082058": "石岡市",
  "082074": "結城市",
  "082082": "龍ケ崎市",
  "082104": "下妻市",
  "082112": "常総市",
  "082121": "常陸太田市",
  "082147": "高萩市",
  "082155": "北茨城市",
  "082163": "笠間市",
  "082171": "取手市",
  "082198": "牛久市",
  "082201": "つくば市",
  "082210": "ひたちなか市",
  "082228": "鹿嶋市",
  "082236": "潮来市",
  "082244": "守谷市",
  "082252": "常陸大宮市",
  "082261": "那珂市",
  "082279": "筑西市",
  "082287": "坂東市",
  "082295": "稲敷市",
  "082309": "かすみがうら市",
  "082317": "桜川市",
  "082325": "神栖市",
  "082333": "行方市",
  "082341": "鉾田市",
  "082350": "つくばみらい市",
  "082368": "小美玉市",
  "083020": "茨城町",
  "083097": "大洗町",
  "083101": "城里町",
  "083411": "東海村",
  "083640": "大子町",
  "084425": "美浦村",
  "084433": "阿見町",
  "084476": "河内町",
  "085219": "八千代町",
  "085421": "五霞町",
  "085464": "境町",
  "085642": "利根町",
  "092011": "宇都宮市",
  "092029": "足利市",
  "092037": "栃木市",
  "092045": "佐野市",
  "092053": "鹿沼市",
  "092061": "日光市",
  "092088": "小山市",
  "092096": "真岡市",
  "092100": "大田原市",
  "092118": "矢板市",
  "092134": "那須塩原市",
  "092142": "さくら市",
  "092151": "那須烏山市",
  "092169": "下野市",
  "093017": "上三川町",
  "093424": "益子町",
  "093432": "茂木町",
  "093441": "市貝町",
  "093459": "芳賀町",
  "093611": "壬生町",
  "093645": "野木町",
  "093840": "塩谷町",
  "093866": "高根沢町",
  "094072": "那須町",
  "094111": "那珂川町",
  "102016": "前橋市",
  "102024": "高崎市",
  "102032": "桐生市",
  "102041": "伊勢崎市",
  "102059": "太田市",
  "102067": "沼田市",
  "102075": "館林市",
  "102083": "渋川市",
  "102091": "藤岡市",
  "102105": "富岡市",
  "102113": "安中市",
  "102121": "みどり市",
  "103446": "榛東村",
  "103454": "吉岡町",
  "103667": "上野村",
  "103675": "神流町",
  "103829": "下仁田町",
  "103837": "南牧村",
  "103845": "甘楽町",
  "104213": "中之条町",
  "104248": "長野原町",
  "104256": "嬬恋村",
  "104264": "草津町",
  "104281": "高山村",
  "104299": "東吾妻町",
  "104434": "片品村",
  "104442": "川場村",
  "104485": "昭和村",
  "104493": "みなかみ町",
  "104647": "玉村町",
  "105210": "板倉町",
  "105228": "明和町",
  "105236": "千代田町",
  "105244": "大泉町",
  "105252": "邑楽町",
  "111007": "さいたま市",
  "112011": "川越市",
  "112020": "熊谷市",
  "112038": "川口市",
  "112062": "行田市",
  "112071": "秩父市",
  "112089": "所沢市",
  "112097": "飯能市",
  "112101": "加須市",
  "112119": "本庄市",
  "112127": "東松山市",
  "112143": "春日部市",
  "112151": "狭山市",
  "112160": "羽生市",
  "112178": "鴻巣市",
  "112186": "深谷市",
  "112194": "上尾市",
  "112216": "草加市",
  "112224": "越谷市",
  "112232": "蕨市",
  "112241": "戸田市",
  "112259": "入間市",
  "112275": "朝霞市",
  "112283": "志木市",
  "112291": "和光市",
  "112305": "新座市",
  "112313": "桶川市",
  "112321": "久喜市",
  "112330": "北本市",
  "112348": "八潮市",
  "112356": "富士見市",
  "112372": "三郷市",
  "112381": "蓮田市",
  "112399": "坂戸市",
  "112402": "幸手市",
  "112411": "鶴ヶ島市",
  "112429": "日高市",
  "112437": "吉川市",
  "112453": "ふじみ野市",
  "112461": "白岡市",
  "113018": "伊奈町",
  "113247": "三芳町",
  "113263": "毛呂山町",
  "113271": "越生町",
  "113417": "滑川町",
  "113425": "嵐山町",
  "113433": "小川町",
  "113468": "川島町",
  "113476": "吉見町",
  "113484": "鳩山町",
  "113492": "ときがわ町",
  "113611": "横瀬町",
  "113620": "皆野町",
  "113638": "長瀞町",
  "113654": "小鹿野町",
  "113697": "東秩父村",
  "113816": "美里町",
  "113832": "神川町",
  "113859": "上里町",
  "114081": "寄居町",
  "114421": "宮代町",
  "114642": "杉戸町",
  "114651": "松伏町",
  "121002": "千葉市",
  "122025": "銚子市",
  "122033": "市川市",
  "122041": "船橋市",
  "122050": "館山市",
  "122068": "木更津市",
  "122076": "松戸市",
  "122084": "野田市",
  "122106": "茂原市",
  "122114": "成田市",
  "122122": "佐倉市",
  "122131": "東金市",
  "122157": "旭市",
  "122165": "習志野市",
  "122173": "柏市",
  "122181": "勝浦市",
  "122190": "市原市",
  "122203": "流山市",
  "122211": "八千代市",
  "122220": "我孫子市",
  "122238": "鴨川市",
  "122246": "鎌ケ谷市",
  "122254": "君津市",
  "122262": "富津市",
  "122271": "浦安市",
  "122289": "四街道市",
  "122297": "袖ケ浦市",
  "122301": "八街市",
  "122319": "印西市",
  "122327": "白井市",
  "122335": "富里市",
  "122343": "南房総市",
  "122351": "匝瑳市",
  "122360": "香取市",
  "122378": "山武市",
  "122386": "いすみ市",
  "122394": "大網白里市",
  "123226": "酒々井町",
  "123293": "栄町",
  "123421": "神崎町",
  "123471": "多古町",
  "123498": "東庄町",
  "124036": "九十九里町",
  "124095": "芝山町",
  "124109": "横芝光町",
  "124214": "一宮町",
  "124222": "睦沢町",
  "124231": "長生村",
  "124249": "白子町",
  "124265": "長柄町",
  "124273": "長南町",
  "124419": "大多喜町",
  "124435": "御宿町",
  "124630": "鋸南町",
  "131016": "千代田区",
  "131024": "中央区",
  "131032": "港区",
  "131041": "新宿区",
  "131059": "文京区",
  "131067": "台東区",
  "131075": "墨田区",
  "131083": "江東区",
  "131091": "品川区",
  "131105": "目黒区",
  "131113": "大田区",
  "131121": "世田谷区",
  "131130": "渋谷区",
  "131148": "中野区",
  "131156": "杉並区",
  "131164": "豊島区",
  "131172": "北区",
  "131181": "荒川区",
  "131199": "板橋区",
  "131202": "練馬区",
  "131211": "足立区",
  "131229": "葛飾区",
  "131237": "江戸川区",
  "132012": "八王子市",
  "132021": "立川市",
  "132039": "武蔵野市",
  "132047": "三鷹市",
  "132055": "青梅市",
  "132063": "府中市",
  "132071": "昭島市",
  "132080": "調布市",
  "132098": "町田市",
  "132101": "小金井市",
  "132110": "小平市",
  "132128": "日野市",
  "132136": "東村山市",
  "132144": "国分寺市",
  "132152": "国立市",
  "132187": "福生市",
  "132195": "狛江市",
  "132209": "東大和市",
  "132217": "清瀬市",
  "132225": "東久留米市",
  "132233": "武蔵村山市",
  "132241": "多摩市",
  "132250": "稲城市",
  "132276": "羽村市",
  "132284": "あきる野市",
  "132292": "西東京市",
  "133035": "瑞穂町",
  "133051": "日の出町",
  "133078": "檜原村",
  "133086": "奥多摩町",
  "133612": "大島町",
  "133621": "利島村",
  "133639": "新島村",
  "133647": "神津島村",
  "133817": "三宅村",
  "133825": "御蔵島村",
  "134015": "八丈町",
  "134023": "青ヶ島村",
  "134210": "小笠原村",
  "141003": "横浜市",
  "141305": "川崎市",
  "141500": "相模原市",
  "142018": "横須賀市",
  "142034": "平塚市",
  "142042": "鎌倉市",
  "142051": "藤沢市",
  "142069": "小田原市",
  "142077": "茅ヶ崎市",
  "142085": "逗子市",
  "142107": "三浦市",
  "142115": "秦野市",
  "142123": "厚木市",
  "142131": "大和市",
  "142140": "伊勢原市",
  "142158": "海老名市",
  "142166": "座間市",
  "142174": "南足柄市",
  "142182": "綾瀬市",
  "143014": "葉山町",
  "143219": "寒川町",
  "143413": "大磯町",
  "143421": "二宮町",
  "143618": "中井町",
  "143626": "大井町",
  "143634": "松田町",
  "143642": "山北町",
  "143669": "開成町",
  "143821": "箱根町",
  "143839": "真鶴町",
  "143847": "湯河原町",
  "144011": "愛川町",
  "144029": "清川村",
  "151009": "新潟市",
  "152021": "長岡市",
  "152048": "三条市",
  "152056": "柏崎市",
  "152064": "新発田市",
  "152081": "小千谷市",
  "152099": "加茂市",
  "152102": "十日町市",
  "152111": "見附市",
  "152129": "村上市",
  "152137": "燕市",
  "152161": "糸魚川市",
  "152170": "妙高市",
  "152188": "五泉市",
  "152226": "上越市",
  "152234": "阿賀野市",
  "152242": "佐渡市",
  "152251": "魚沼市",
  "152269": "南魚沼市",
  "152277": "胎内市",
  "153079": "聖籠町",
  "153427": "弥彦村",
  "153613": "田上町",
  "153851": "阿賀町",
  "154059": "出雲崎町",
  "154610": "湯沢町",
  "154822": "津南町",
  "155047": "刈羽村",
  "155811": "関川村",
  "155861": "粟島浦村",
  "162019": "富山市",
  "162027": "高岡市",
  "162043": "魚津市",
  "162051": "氷見市",
  "162060": "滑川市",
  "162078": "黒部市",
  "162086": "砺波市",
  "162094": "小矢部市",
  "162108": "南砺市",
  "162116": "射水市",
  "163210": "舟橋村",
  "163228": "上市町",
  "163236": "立山町",
  "163422": "入善町",
  "163431": "朝日町",
  "172014": "金沢市",
  "172022": "七尾市",
  "172031": "小松市",
  "172049": "輪島市",
  "172057": "珠洲市",
  "172065": "加賀市",
  "172073": "羽咋市",
  "172090": "かほく市",
  "172103": "白山市",
  "172111": "能美市",
  "172120": "野々市市",
  "173240": "川北町",
  "173614": "津幡町",
  "173657": "内灘町",
  "173843": "志賀町",
  "173860": "宝達志水町",
  "174076": "中能登町",
  "174611": "穴水町",
  "174637": "能登町",
  "182010": "福井市",
  "182028": "敦賀市",
  "182044": "小浜市",
  "182052": "大野市",
  "182061": "勝山市",
  "182079": "鯖江市",
  "182087": "あわら市",
  "182095": "越前市",
  "182109": "坂井市",
  "183229": "永平寺町",
  "183822": "池田町",
  "184047": "南越前町",
  "184233": "越前町",
  "184420": "美浜町",
  "184811": "高浜町",
  "184837": "おおい町",
  "185019": "若狭町",
  "192015": "甲府市",
  "192023": "富士吉田市",
  "192040": "都留市",
  "192058": "山梨市",
  "192066": "大月市",
  "192074": "韮崎市",
  "192082": "南アルプス市",
  "192091": "北杜市",
  "192104": "甲斐市",
  "192112": "笛吹市",
  "192121": "上野原市",
  "192139": "甲州市",
  "192147": "中央市",
  "193461": "市川三郷町",
  "193640": "早川町",
  "193658": "身延町",
  "193666": "南部町",
  "193682": "富士川町",
  "193844": "昭和町",
  "194221": "道志村",
  "194239": "西桂町",
  "194247": "忍野村",
  "194255": "山中湖村",
  "194298": "鳴沢村",
  "194301": "富士河口湖町",
  "194425": "小菅村",
  "194433": "丹波山村",
  "202011": "長野市",
  "202029": "松本市",
  "202037": "上田市",
  "202045": "岡谷市",
  "202053": "飯田市",
  "202061": "諏訪市",
  "202070": "須坂市",
  "202088": "小諸市",
  "202096": "伊那市",
  "202100": "駒ヶ根市",
  "202118": "中野市",
  "202126": "大町市",
  "202134": "飯山市",
  "202142": "茅野市",
  "202151": "塩尻市",
  "202177": "佐久市",
  "202185": "千曲市",
  "202193": "東御市",
  "202207": "安曇野市",
  "203033": "小海町",
  "203041": "川上村",
  "203050": "南牧村",
  "203068": "南相木村",
  "203076": "北相木村",
  "203092": "佐久穂町",
  "203211": "軽井沢町",
  "203238": "御代田町",
  "203246": "立科町",
  "203491": "青木村",
  "203505": "長和町",
  "203611": "下諏訪町",
  "203629": "富士見町",
  "203637": "原村",
  "203823": "辰野町",
  "203831": "箕輪町",
  "203840": "飯島町",
  "203858": "南箕輪村",
  "203866": "中川村",
  "203882": "宮田村",
  "204021": "松川町",
  "204030": "高森町",
  "204048": "阿南町",
  "204072": "阿智村",
  "204099": "平谷村",
  "204102": "根羽村",
  "204111": "下條村",
  "204129": "売木村",
  "204137": "天龍村",
  "204145": "泰阜村",
  "204153": "喬木村",
  "204161": "豊丘村",
  "204170": "大鹿村",
  "204226": "上松町",
  "204234": "南木曽町",
  "204251": "木祖村",
  "204293": "王滝村",
  "204307": "大桑村",
  "204323": "木曽町",
  "204463": "麻績村",
  "204480": "生坂村",
  "204501": "山形村",
  "204510": "朝日村",
  "204528": "筑北村",
  "204811": "池田町",
  "204820": "松川村",
  "204854": "白馬村",
  "204862": "小谷村",
  "205214": "坂城町",
  "205419": "小布施町",
  "205435": "高山村",
  "205613": "山ノ内町",
  "205621": "木島平村",
  "205630": "野沢温泉村",
  "205834": "信濃町",
  "205885": "小川村",
  "205907": "飯綱町",
  "206024": "栄村",
  "212016": "岐阜市",
  "212024": "大垣市",
  "212032": "高山市",
  "212041": "多治見市",
  "212059": "関市",
  "212067": "中津川市",
  "212075": "美濃市",
  "212083": "瑞浪市",
  "212091": "羽島市",
  "212105": "恵那市",
  "212113": "美濃加茂市",
  "212121": "土岐市",
  "212130": "各務原市",
  "212148": "可児市",
  "212156": "山県市",
  "212164": "瑞穂市",
  "212172": "飛騨市",
  "212181": "本巣市",
  "212199": "郡上市",
  "212202": "下呂市",
  "212211": "海津市",
  "213021": "岐南町",
  "213039": "笠松町",
  "213411": "養老町",
  "213616": "垂井町",
  "213624": "関ケ原町",
  "213811": "神戸町",
  "213829": "輪之内町",
  "213837": "安八町",
  "214019": "揖斐川町",
  "214035": "大野町",
  "214043": "池田町",
  "214213": "北方町",
  "215015": "坂祝町",
  "215023": "富加町",
  "215031": "川辺町",
  "215040": "七宗町",
  "215058": "八百津町",
  "215066": "白川町",
  "215074": "東白川村",
  "215210": "御嵩町",
  "216046": "白川村",
  "221007": "静岡市",
  "221309": "浜松市",
  "222038": "沼津市",
  "222054": "熱海市",
  "222062": "三島市",
  "222071": "富士宮市",
  "222089": "伊東市",
  "222097": "島田市",
  "222101": "富士市",
  "222119": "磐田市",
  "222127": "焼津市",
  "222135": "掛川市",
  "222143": "藤枝市",
  "222151": "御殿場市",
  "222160": "袋井市",
  "222194": "下田市",
  "222208": "裾野市",
  "222216": "湖西市",
  "222224": "伊豆市",
  "222232": "御前崎市",
  "222241": "菊川市",
  "222259": "伊豆の国市",
  "222267": "牧之原市",
  "223018": "東伊豆町",
  "223026": "河津町",
  "223042": "南伊豆町",
  "223051": "松崎町",
  "223069": "西伊豆町",
  "223255": "函南町",
  "223417": "清水町",
  "223425": "長泉町",
  "223441": "小山町",
  "224243": "吉田町",
  "224294": "川根本町",
  "224618": "森町",
  "231002": "名古屋市",
  "232017": "豊橋市",
  "232025": "岡崎市",
  "232033": "一宮市",
  "232041": "瀬戸市",
  "232050": "半田市",
  "232068": "春日井市",
  "232076": "豊川市",
  "232084": "津島市",
  "232092": "碧南市",
  "232106": "刈谷市",
  "232114": "豊田市",
  "232122": "安城市",
  "232131": "西尾市",
  "232149": "蒲郡市",
  "232157": "犬山市",
  "232165": "常滑市",
  "232173": "江南市",
  "232190": "小牧市",
  "232203": "稲沢市",
  "232211": "新城市",
  "232220": "東海市",
  "232238": "大府市",
  "232246": "知多市",
  "232254": "知立市",
  "232262": "尾張旭市",
  "232271": "高浜市",
  "232289": "岩倉市",
  "232297": "豊明市",
  "232301": "日進市",
  "232319": "田原市",
  "232327": "愛西市",
  "232335": "清須市",
  "232343": "北名古屋市",
  "232351": "弥富市",
  "232360": "みよし市",
  "232378": "あま市",
  "232386": "長久手市",
  "233021": "東郷町",
  "233421": "豊山町",
  "233617": "大口町",
  "233625": "扶桑町",
  "234249": "大治町",
  "234257": "蟹江町",
  "234273": "飛島村",
  "234419": "阿久比町",
  "234427": "東浦町",
  "234451": "南知多町",
  "234460": "美浜町",
  "234478": "武豊町",
  "235016": "幸田町",
  "235610": "設楽町",
  "235628": "東栄町",
  "235636": "豊根村",
  "242012": "津市",
  "242021": "四日市市",
  "242039": "伊勢市",
  "242047": "松阪市",
  "242055": "桑名市",
  "242071": "鈴鹿市",
  "242080": "名張市",
  "242098": "尾鷲市",
  "242101": "亀山市",
  "242110": "鳥羽市",
  "242128": "熊野市",
  "242144": "いなべ市",
  "242152": "志摩市",
  "242161": "伊賀市",
  "243035": "木曽岬町",
  "243248": "東員町",
  "243418": "菰野町",
  "243434": "朝日町",
  "243442": "川越町",
  "244414": "多気町",
  "244422": "明和町",
  "244431": "大台町",
  "244619": "玉城町",
  "244708": "度会町",
  "244716": "大紀町",
  "244724": "南伊勢町",
  "245437": "紀北町",
  "245615": "御浜町",
  "245623": "紀宝町",
  "252018": "大津市",
  "252026": "彦根市",
  "252034": "長浜市",
  "252042": "近江八幡市",
  "252069": "草津市",
  "252077": "守山市",
  "252085": "栗東市",
  "252093": "甲賀市",
  "252107": "野洲市",
  "252115": "湖南市",
  "252123": "高島市",
  "252131": "東近江市",
  "252140": "米原市",
  "253839": "日野町",
  "253847": "竜王町",
  "254258": "愛荘町",
  "254410": "豊郷町",
  "254428": "甲良町",
  "254436": "多賀町",
  "261009": "京都市",
  "262013": "福知山市",
  "262021": "舞鶴市",
  "262030": "綾部市",
  "262048": "宇治市",
  "262056": "宮津市",
  "262064": "亀岡市",
  "262072": "城陽市",
  "262081": "向日市",
  "262099": "長岡京市",
  "262102": "八幡市",
  "262111": "京田辺市",
  "262129": "京丹後市",
  "262137": "南丹市",
  "262145": "木津川市",
  "263036": "大山崎町",
  "263222": "久御山町",
  "263435": "井手町",
  "263443": "宇治田原町",
  "263648": "笠置町",
  "263656": "和束町",
  "263664": "精華町",
  "263672": "南山城村",
  "264075": "京丹波町",
  "264636": "伊根町",
  "264652": "与謝野町",
  "271004": "大阪市",
  "271403": "堺市",
  "272027": "岸和田市",
  "272035": "豊中市",
  "272043": "池田市",
  "272051": "吹田市",
  "272060": "泉大津市",
  "272078": "高槻市",
  "272086": "貝塚市",
  "272094": "守口市",
  "272108": "枚方市",
  "272116": "茨木市",
  "272124": "八尾市",
  "272132": "泉佐野市",
  "272141": "富田林市",
  "272159": "寝屋川市",
  "272167": "河内長野市",
  "272175": "松原市",
  "272183": "大東市",
  "272191": "和泉市",
  "272205": "箕面市",
  "272213": "柏原市",
  "272221": "羽曳野市",
  "272230": "門真市",
  "272248": "摂津市",
  "272256": "高石市",
  "272264": "藤井寺市",
  "272272": "東大阪市",
  "272281": "泉南市",
  "272299": "四條畷市",
  "272302": "交野市",
  "272311": "大阪狭山市",
  "272329": "阪南市",
  "273015": "島本町",
  "273210": "豊能町",
  "273228": "能勢町",
  "273414": "忠岡町",
  "273619": "熊取町",
  "273627": "田尻町",
  "273660": "岬町",
  "273813": "太子町",
  "273821": "河南町",
  "273830": "千早赤阪村",
  "281000": "神戸市",
  "282014": "姫路市",
  "282022": "尼崎市",
  "282031": "明石市",
  "282049": "西宮市",
  "282057": "洲本市",
  "282065": "芦屋市",
  "282073": "伊丹市",
  "282081": "相生市",
  "282090": "豊岡市",
  "282103": "加古川市",
  "282120": "赤穂市",
  "282138": "西脇市",
  "282146": "宝塚市",
  "282154": "三木市",
  "282162": "高砂市",
  "282171": "川西市",
  "282189": "小野市",
  "282197": "三田市",
  "282201": "加西市",
  "282219": "丹波篠山市",
  "282227": "養父市",
  "282235": "丹波市",
  "282243": "南あわじ市",
  "282251": "朝来市",
  "282260": "淡路市",
  "282278": "宍粟市",
  "282286": "加東市",
  "282294": "たつの市",
  "283011": "猪名川町",
  "283657": "多可町",
  "283819": "稲美町",
  "283827": "播磨町",
  "284424": "市川町",
  "284432": "福崎町",
  "284467": "神河町",
  "284645": "太子町",
  "284815": "上郡町",
  "285013": "佐用町",
  "285854": "香美町",
  "285862": "新温泉町",
  "292010": "奈良市",
  "292028": "大和高田市",
  "292036": "大和郡山市",
  "292044": "天理市",
  "292052": "橿原市",
  "292061": "桜井市",
  "292079": "五條市",
  "292087": "御所市",
  "292095": "生駒市",
  "292109": "香芝市",
  "292117": "葛城市",
  "292125": "宇陀市",
  "293229": "山添村",
  "293423": "平群町",
  "293431": "三郷町",
  "293440": "斑鳩町",
  "293458": "安堵町",
  "293610": "川西町",
  "293628": "三宅町",
  "293636": "田原本町",
  "293857": "曽爾村",
  "293865": "御杖村",
  "294012": "高取町",
  "294021": "明日香村",
  "294241": "上牧町",
  "294250": "王寺町",
  "294268": "広陵町",
  "294276": "河合町",
  "294411": "吉野町",
  "294420": "大淀町",
  "294438": "下市町",
  "294446": "黒滝村",
  "294462": "天川村",
  "294471": "野迫川村",
  "294497": "十津川村",
  "294501": "下北山村",
  "294519": "上北山村",
  "294527": "川上村",
  "294535": "東吉野村",
  "302015": "和歌山市",
  "302023": "海南市",
  "302031": "橋本市",
  "302040": "有田市",
  "302058": "御坊市",
  "302066": "田辺市",
  "302074": "新宮市",
  "302082": "紀の川市",
  "302091": "岩出市",
  "303046": "紀美野町",
  "303411": "かつらぎ町",
  "303437": "九度山町",
  "303445": "高野町",
  "303615": "湯浅町",
  "303623": "広川町",
  "303666": "有田川町",
  "303810": "美浜町",
  "303828": "日高町",
  "303836": "由良町",
  "303909": "印南町",
  "303917": "みなべ町",
  "303925": "日高川町",
  "304018": "白浜町",
  "304042": "上富田町",
  "304069": "すさみ町",
  "304212": "那智勝浦町",
  "304221": "太地町",
  "304247": "古座川町",
  "304271": "北山村",
  "304280": "串本町",
  "312011": "鳥取市",
  "312029": "米子市",
  "312037": "倉吉市",
  "312045": "境港市",
  "313025": "岩美町",
  "313254": "若桜町",
  "313289": "智頭町",
  "313297": "八頭町",
  "313645": "三朝町",
  "313700": "湯梨浜町",
  "313718": "琴浦町",
  "313726": "北栄町",
  "313840": "日吉津村",
  "313866": "大山町",
  "313891": "南部町",
  "313904": "伯耆町",
  "314013": "日南町",
  "314021": "日野町",
  "314030": "江府町",
  "322016": "松江市",
  "322024": "浜田市",
  "322032": "出雲市",
  "322041": "益田市",
  "322059": "大田市",
  "322067": "安来市",
  "322075": "江津市",
  "322091": "雲南市",
  "323438": "奥出雲町",
  "323861": "飯南町",
  "324418": "川本町",
  "324485": "美郷町",
  "324493": "邑南町",
  "325015": "津和野町",
  "325058": "吉賀町",
  "325252": "海士町",
  "325261": "西ノ島町",
  "325279": "知夫村",
  "325287": "隠岐の島町",
  "331007": "岡山市",
  "332020": "倉敷市",
  "332038": "津山市",
  "332046": "玉野市",
  "332054": "笠岡市",
  "332071": "井原市",
  "332089": "総社市",
  "332097": "高梁市",
  "332101": "新見市",
  "332119": "備前市",
  "332127": "瀬戸内市",
  "332135": "赤磐市",
  "332143": "真庭市",
  "332151": "美作市",
  "332160": "浅口市",
  "333468": "和気町",
  "334235": "早島町",
  "334456": "里庄町",
  "334618": "矢掛町",
  "335860": "新庄村",
  "336068": "鏡野町",
  "336220": "勝央町",
  "336238": "奈義町",
  "336432": "西粟倉村",
  "336637": "久米南町",
  "336661": "美咲町",
  "336815": "吉備中央町",
  "341002": "広島市",
  "342025": "呉市",
  "342033": "竹原市",
  "342041": "三原市",
  "342050": "尾道市",
  "342076": "福山市",
  "342084": "府中市",
  "342092": "三次市",
  "342106": "庄原市",
  "342114": "大竹市",
  "342122": "東広島市",
  "342131": "廿日市市",
  "342149": "安芸高田市",
  "342157": "江田島市",
  "343021": "府中町",
  "343048": "海田町",
  "343072": "熊野町",
  "343099": "坂町",
  "343684": "安芸太田町",
  "343692": "北広島町",
  "344311": "大崎上島町",
  "344621": "世羅町",
  "345458": "神石高原町",
  "352012": "下関市",
  "352021": "宇部市",
  "352039": "山口市",
  "352047": "萩市",
  "352063": "防府市",
  "352071": "下松市",
  "352080": "岩国市",
  "352101": "光市",
  "352110": "長門市",
  "352128": "柳井市",
  "352136": "美祢市",
  "352152": "周南市",
  "352161": "山陽小野田市",
  "353051": "周防大島町",
  "353213": "和木町",
  "353418": "上関町",
  "353434": "田布施町",
  "353442": "平生町",
  "355020": "阿武町",
  "362018": "徳島市",
  "362026": "鳴門市",
  "362034": "小松島市",
  "362042": "阿南市",
  "362051": "吉野川市",
  "362069": "阿波市",
  "362077": "美馬市",
  "362085": "三好市",
  "363014": "勝浦町",
  "363022": "上勝町",
  "363219": "佐那河内村",
  "363413": "石井町",
  "363421": "神山町",
  "363685": "那賀町",
  "363839": "牟岐町",
  "363871": "美波町",
  "363880": "海陽町",
  "364011": "松茂町",
  "364029": "北島町",
  "364037": "藍住町",
  "364045": "板野町",
  "364053": "上板町",
  "364681": "つるぎ町",
  "364894": "東みよし町",
  "372013": "高松市",
  "372021": "丸亀市",
  "372030": "坂出市",
  "372048": "善通寺市",
  "372056": "観音寺市",
  "372064": "さぬき市",
  "372072": "東かがわ市",
  "372081": "三豊市",
  "373222": "土庄町",
  "373249": "小豆島町",
  "373419": "三木町",
  "373648": "直島町",
  "373869": "宇多津町",
  "373877": "綾川町",
  "374032": "琴平町",
  "374041": "多度津町",
  "374067": "まんのう町",
  "382019": "松山市",
  "382027": "今治市",
  "382035": "宇和島市",
  "382043": "八幡浜市",
  "382051": "新居浜市",
  "382060": "西条市",
  "382078": "大洲市",
  "382108": "伊予市",
  "382132": "四国中央市",
  "382141": "西予市",
  "382159": "東温市",
  "383562": "上島町",
  "383864": "久万高原町",
  "384011": "松前町",
  "384020": "砥部町",
  "384224": "内子町",
  "384429": "伊方町",
  "384844": "松野町",
  "384887": "鬼北町",
  "385069": "愛南町",
  "392014": "高知市",
  "392022": "室戸市",
  "392031": "安芸市",
  "392049": "南国市",
  "392057": "土佐市",
  "392065": "須崎市",
  "392081": "宿毛市",
  "392090": "土佐清水市",
  "392103": "四万十市",
  "392111": "香南市",
  "392120": "香美市",
  "393011": "東洋町",
  "393029": "奈半利町",
  "393037": "田野町",
  "393045": "安田町",
  "393053": "北川村",
  "393061": "馬路村",
  "393070": "芸西村",
  "393410": "本山町",
  "393444": "大豊町",
  "393631": "土佐町",
  "393649": "大川村",
  "393860": "いの町",
  "393878": "仁淀川町",
  "394017": "中土佐町",
  "394025": "佐川町",
  "394033": "越知町",
  "394050": "梼原町",
  "394106": "日高村",
  "394114": "津野町",
  "394122": "四万十町",
  "394246": "大月町",
  "394271": "三原村",
  "394289": "黒潮町",
  "401005": "北九州市",
  "401307": "福岡市",
  "402028": "大牟田市",
  "402036": "久留米市",
  "402044": "直方市",
  "402052": "飯塚市",
  "402061": "田川市",
  "402079": "柳川市",
  "402109": "八女市",
  "402117": "筑後市",
  "402125": "大川市",
  "402133": "行橋市",
  "402141": "豊前市",
  "402150": "中間市",
  "402168": "小郡市",
  "402176": "筑紫野市",
  "402184": "春日市",
  "402192": "大野城市",
  "402206": "宗像市",
  "402214": "太宰府市",
  "402231": "古賀市",
  "402249": "福津市",
  "402257": "うきは市",
  "402265": "宮若市",
  "402273": "嘉麻市",
  "402281": "朝倉市",
  "402290": "みやま市",
  "402303": "糸島市",
  "402311": "那珂川市",
  "403415": "宇美町",
  "403423": "篠栗町",
  "403431": "志免町",
  "403440": "須恵町",
  "403458": "新宮町",
  "403482": "久山町",
  "403491": "粕屋町",
  "403814": "芦屋町",
  "403822": "水巻町",
  "403831": "岡垣町",
  "403849": "遠賀町",
  "404012": "小竹町",
  "404021": "鞍手町",
  "404217": "桂川町",
  "404471": "筑前町",
  "404489": "東峰村",
  "405035": "大刀洗町",
  "405221": "大木町",
  "405442": "広川町",
  "406015": "香春町",
  "406023": "添田町",
  "406040": "糸田町",
  "406058": "川崎町",
  "406082": "大任町",
  "406091": "赤村",
  "406104": "福智町",
  "406210": "苅田町",
  "406252": "みやこ町",
  "406422": "吉富町",
  "406465": "上毛町",
  "406473": "築上町",
  "412015": "佐賀市",
  "412023": "唐津市",
  "412031": "鳥栖市",
  "412040": "多久市",
  "412058": "伊万里市",
  "412066": "武雄市",
  "412074": "鹿島市",
  "412082": "小城市",
  "412091": "嬉野市",
  "412104": "神埼市",
  "413275": "吉野ヶ里町",
  "413411": "基山町",
  "413453": "上峰町",
  "413461": "みやき町",
  "413879": "玄海町",
  "414018": "有田町",
  "414239": "大町町",
  "414247": "江北町",
  "414255": "白石町",
  "414417": "太良町",
  "422011": "長崎市",
  "422029": "佐世保市",
  "422037": "島原市",
  "422045": "諫早市",
  "422053": "大村市",
  "422070": "平戸市",
  "422088": "松浦市",
  "422096": "対馬市",
  "422100": "壱岐市",
  "422118": "五島市",
  "422126": "西海市",
  "422134": "雲仙市",
  "422142": "南島原市",
  "423076": "長与町",
  "423084": "時津町",
  "423211": "東彼杵町",
  "423220": "川棚町",
  "423238": "波佐見町",
  "423831": "小値賀町",
  "423912": "佐々町",
  "424111": "新上五島町",
  "431001": "熊本市",
  "432024": "八代市",
  "432032": "人吉市",
  "432041": "荒尾市",
  "432059": "水俣市",
  "432067": "玉名市",
  "432083": "山鹿市",
  "432105": "菊池市",
  "432113": "宇土市",
  "432121": "上天草市",
  "432130": "宇城市",
  "432148": "阿蘇市",
  "432156": "天草市",
  "432164": "合志市",
  "433489": "美里町",
  "433641": "玉東町",
  "433675": "南関町",
  "433683": "長洲町",
  "433691": "和水町",
  "434035": "大津町",
  "434043": "菊陽町",
  "434230": "南小国町",
  "434248": "小国町",
  "434256": "産山村",
  "434281": "高森町",
  "434329": "西原村",
  "434337": "南阿蘇村",
  "434418": "御船町",
  "434426": "嘉島町",
  "434434": "益城町",
  "434442": "甲佐町",
  "434477": "山都町",
  "434680": "氷川町",
  "434825": "芦北町",
  "434841": "津奈木町",
  "435015": "錦町",
  "435058": "多良木町",
  "435066": "湯前町",
  "435074": "水上村",
  "435104": "相良村",
  "435112": "五木村",
  "435121": "山江村",
  "435139": "球磨村",
  "435147": "あさぎり町",
  "435317": "苓北町",
  "442011": "大分市",
  "442020": "別府市",
  "442038": "中津市",
  "442046": "日田市",
  "442054": "佐伯市",
  "442062": "臼杵市",
  "442071": "津久見市",
  "442089": "竹田市",
  "442097": "豊後高田市",
  "442101": "杵築市",
  "442119": "宇佐市",
  "442127": "豊後大野市",
  "442135": "由布市",
  "442143": "国東市",
  "443221": "姫島村",
  "443417": "日出町",
  "444618": "九重町",
  "444626": "玖珠町",
  "452017": "宮崎市",
  "452025": "都城市",
  "452033": "延岡市",
  "452041": "日南市",
  "452050": "小林市",
  "452068": "日向市",
  "452076": "串間市",
  "452084": "西都市",
  "452092": "えびの市",
  "453412": "三股町",
  "453617": "高原町",
  "453820": "国富町",
  "453838": "綾町",
  "454010": "高鍋町",
  "454028": "新富町",
  "454036": "西米良村",
  "454044": "木城町",
  "454052": "川南町",
  "454061": "都農町",
  "454214": "門川町",
  "454290": "諸塚村",
  "454303": "椎葉村",
  "454311": "美郷町",
  "454419": "高千穂町",
  "454427": "日之影町",
  "454435": "五ヶ瀬町",
  "462012": "鹿児島市",
  "462039": "鹿屋市",
  "462047": "枕崎市",
  "462063": "阿久根市",
  "462080": "出水市",
  "462101": "指宿市",
  "462136": "西之表市",
  "462144": "垂水市",
  "462152": "薩摩川内市",
  "462161": "日置市",
  "462179": "曽於市",
  "462187": "霧島市",
  "462195": "いちき串木野市",
  "462209": "南さつま市",
  "462217": "志布志市",
  "462225": "奄美市",
  "462233": "南九州市",
  "462241": "伊佐市",
  "462250": "姶良市",
  "463035": "三島村",
  "463043": "十島村",
  "463922": "さつま町",
  "464040": "長島町",
  "464520": "湧水町",
  "464686": "大崎町",
  "464821": "東串良町",
  "464902": "錦江町",
  "464911": "南大隅町",
  "464929": "肝付町",
  "465011": "中種子町",
  "465020": "南種子町",
  "465054": "屋久島町",
  "465232": "大和村",
  "465241": "宇検村",
  "465259": "瀬戸内町",
  "465275": "龍郷町",
  "465291": "喜界町",
  "465305": "徳之島町",
  "465313": "天城町",
  "465321": "伊仙町",
  "465330": "和泊町",
  "465348": "知名町",
  "465356": "与論町",
  "472018": "那覇市",
  "472051": "宜野湾市",
  "472077": "石垣市",
  "472085": "浦添市",
  "472093": "名護市",
  "472107": "糸満市",
  "472115": "沖縄市",
  "472123": "豊見城市",
  "472131": "うるま市",
  "472140": "宮古島市",
  "472158": "南城市",
  "473014": "国頭村",
  "473022": "大宜味村",
  "473031": "東村",
  "473065": "今帰仁村",
  "473081": "本部町",
  "473111": "恩納村",
  "473138": "宜野座村",
  "473146": "金武町",
  "473154": "伊江村",
  "473243": "読谷村",
  "473251": "嘉手納町",
  "473260": "北谷町",
  "473278": "北中城村",
  "473286": "中城村",
  "473294": "西原町",
  "473481": "与那原町",
  "473502": "南風原町",
  "473537": "渡嘉敷村",
  "473545": "座間味村",
  "473553": "粟国村",
  "473561": "渡名喜村",
  "473570": "南大東村",
  "473588": "北大東村",
  "473596": "伊平屋村",
  "473600": "伊是名村",
  "473618": "久米島町",
  "473626": "八重瀬町",
  "473758": "多良間村",
  "473812": "竹富町",
  "473821": "与那国町"
};