import { withRouter, Link } from 'react-router-dom';
import React from 'react';
import { AdsCardForForm, AdsCardForList } from './AdsCard';
import List from "./List";

const ResultCard = withRouter(function(props) {
  const id = props.id;
  const schoolName = props.schoolName;
  const ruleName = props.ruleName;
  const pref = props.pref;
  const city = props.city;

  const linkTo = "/rule/" + id;
  function handlePageMove(e) {
    e.preventDefault();
    props.history.push(linkTo);
  }
  

  return (
    <div className="container px-5 mx-auto">
      <div className="flex items-center lg:w-3/5 mx-auto mb-10">
        <Link to="/#" onClick={handlePageMove} className="flex-grow">
          <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col hover:bg-gray-100">
            <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">{schoolName}（{pref} {city}）</h2>
              <p className="leading-relaxed text-base">{ruleName}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  ); 
});

function IsResult(props) {
  // console.log("IsResult(props)実行開始");
  // console.log(props);
  const result = props.searchResultArray;
  const resultCount = props.searchResultCount;
  const resultPageCount = props.searchResultPageCount;
  const resultCurrentPage = props.searchResultCurrentPage;

  //TODO: resultCountがundefinedのときはスピナーつけたい

  // const [ offset, setOffset ] = useState(0); // 何番目のアイテムから表示するか
  // const perPage = 5; // 1ページあたりに表示したいアイテムの数

  // // クリック時のfunction
  // const handlePageChange = (data) => {
  //   let page_number = data['selected']; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
  //   setOffset(page_number*perPage); // offsetを変更し、表示開始するアイテムの番号を変更
  // }

  function handlePagePrevious(e) {
    e.preventDefault();
    if(resultCurrentPage === 0) {
      return null;
    } else {
      // console.log("前へ"+resultCurrentPage);
      props.onPagePrev();
    }
  }
  function handlePageNext(e) {
    e.preventDefault();
    if(resultCurrentPage + 1 === resultPageCount) {
      return null;
    } else {
      // console.log("次へ"+resultCurrentPage);
      props.onPageNext();
    }
  }
  const handlePageMove = (page) => () => {
    // resultCurrentPage = page;
    // console.log(page+"へ");
    props.onPageMove(page);
  }

  const previousAriaDisabled = resultCurrentPage === 0 ? 'true' : 'false';
  const nextAriaDisabled = resultCurrentPage === resultPageCount - 1 ? 'true' : 'false';
  const previousClassName = 'pagination__previous' + (resultCurrentPage === 0 ? ' pagination__disabled' : '');
  const nextClassName = 'pagination__next' + (resultCurrentPage === resultPageCount - 1 ? ' pagination__disabled' : '');

  function pageLink() {
    // console.log("pageLink()実行開始");
    const marginPagesDisplayed = 2; // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
    const pageRangeDisplayed = 3; // 【奇数で！！】アクティブなページを基準にして、そこからいくつページ数を表示するか
    const halfPageRangeDisplayed = pageRangeDisplayed / 2;
    const items = [];
    let item = "";
    let listClassName = null;
    let ariaLabel = '';
    let ariaCurrent = null;

    
    if (resultPageCount <=  marginPagesDisplayed * 2 + pageRangeDisplayed) {

      // 表示したい個数より全体ページ数が少ない場合（全ページ表示）
      for (let i=1; i<resultPageCount+1; i++) {
        if( i === resultCurrentPage + 1 ) {
          listClassName = 'active';
          ariaLabel = 'Page ' + i + ' is your current page';
          ariaCurrent = 'page';
        } else {
          listClassName = null;
          ariaLabel = 'Page ' + i;
          ariaCurrent = null;
        }
        item = <li className={listClassName} key={i}>
        <a role="button" tabIndex="0" onClick={handlePageMove(i - 1)} aria-label={ariaLabel} aria-current={ariaCurrent}>{i}</a>
      </li>;
        items.push(item);
      }
    } else {
      //　表示を省略するページネーションがある場合
// console.log("9ページより多いので省略しないとね！");

      // まずは最初の部分を生成
      for (let i=1; i<marginPagesDisplayed+1; i++) {
        // console.log("最初の部分を生成中：　i = " + i);
        if( i === resultCurrentPage + 1 ) {
          listClassName = 'active';
          ariaLabel = 'Page ' + i + ' is your current page';
          ariaCurrent = 'page';
        } else {
          listClassName = null;
          ariaLabel = 'Page ' + i;
          ariaCurrent = null;
        }
        item = <li className={listClassName} key={i}>
        <a role="button" tabIndex="0" onClick={handlePageMove(i - 1)} aria-label={ariaLabel} aria-current={ariaCurrent}>{i}</a>
      </li>;
        items.push(item);
      }
      // 左側
      let leftSideStart = Math.ceil(resultCurrentPage + 1 - halfPageRangeDisplayed);
      // console.log(resultCurrentPage + 1 + halfPageRangeDisplayed);
      let rightSideEnd = Math.floor(resultCurrentPage + 1 + halfPageRangeDisplayed);
      let leftSideBreak = false;
      let rightSideBreak = false;

      // console.log("**************左側ここから**************");
      // console.log("resultCurrentPage: "+resultCurrentPage);
      // console.log("halfPageRangeDisplayed: "+halfPageRangeDisplayed);
      // console.log("ひきざん："+(resultCurrentPage - halfPageRangeDisplayed));
// console.log("leftSideStart: "+leftSideStart);
// console.log("rightSideEnd: "+rightSideEnd);


      if(leftSideStart > (marginPagesDisplayed + 1)) {
// console.log("最初の部分と中央部分に…がいるよ！");

        // 現在のページから、中央部分の表示数の半分を除いたもの（leftSide）が、最初の表示ページ数＋1より大きい場合、
        // つまり、最初の表示と中央部分の表示に隙間がある場合
        // 最初の表示をしてから、…を入れて、
        // leftSideを超えるページ数から、中央部分の表示数分表示させる。

        leftSideBreak = true;
        // leftSideStart = leftSideStart;

      } else {
// console.log("最初の部分と中央部分に…はいらないよ！");

        // それ以外の場合は、最初の表示＋1ページから中央部分の表示数分表示させる。
        leftSideStart = marginPagesDisplayed + 1;
        // console.log("leftSideStartを3ページ目からにするよ： "+leftSideStart);
        // console.log("rightSideEndはそのままだよ： "+rightSideEnd);

      }

      // 右側
      // 中央部分表示開始ページ＋中央部分表示数-1が中央部分最終ページ
      // 中央部分最終ページ＞＝全ページ数-最後の表示数以上なら・・・不要で
      // 全ページ数-最後の表示数まで生成して、最後の表示を追加する
      // console.log("**************右側ここから**************")
      if (rightSideEnd >= resultPageCount - marginPagesDisplayed) {
// console.log("中央部分と最後の部分に…はいらないよ！");

        rightSideEnd = resultPageCount - marginPagesDisplayed;
        // console.log("rightSideEndを全ページ数-2ページ目までにするよ： "+rightSideEnd);

      } else {
        // 中央部分最終ページと最後の表示に隙間がある場合
        // console.log("中央部分と最後の部分に…がいるよ！");
        // rightSideEnd = rightSideEnd;
        rightSideBreak = true;
      }

      if (leftSideBreak) {
        items.push('…');
        // console.log("左の…を入れたよ");
      }

      for (let i=leftSideStart; i<rightSideEnd+1; i++) {
        // console.log("中央部分を作るよ： i = "+i);
        if( i === resultCurrentPage + 1 ) {
          listClassName = 'active';
          ariaLabel = 'Page ' + i + ' is your current page';
          ariaCurrent = 'page';
        } else {
          listClassName = null;
          ariaLabel = 'Page ' + i;
          ariaCurrent = null;
        }
        item = <li className={listClassName} key={i}>
        <a role="button" tabIndex="0" onClick={handlePageMove(i - 1)} aria-label={ariaLabel} aria-current={ariaCurrent}>{i}</a>
      </li>;
        items.push(item);
      }

      if (rightSideBreak) {
        // console.log("右の…を入れたよ");
        items.push('…');
      }

      // 最後の部分を生成
      for (let i=resultPageCount-marginPagesDisplayed+1; i<resultPageCount+1; i++) {
        // console.log("最後の部分を生成してるよ： i = "+i);
        if( i === resultCurrentPage + 1 ) {
          listClassName = 'active';
          ariaLabel = 'Page ' + i + ' is your current page';
          ariaCurrent = 'page';
        } else {
          listClassName = null;
          ariaLabel = 'Page ' + i;
          ariaCurrent = null;
        }
        item = <li className={listClassName} key={i}>
        <a role="button" tabIndex="0" onClick={handlePageMove(i - 1)} aria-label={ariaLabel} aria-current={ariaCurrent}>{i}</a>
      </li>;
        items.push(item);
      }


    }
    return items;
  }


  return(
    <>
      <section className="text-gray-600 body-font w-4/5">
        <div className="container px-5 mx-auto mb-8">
          <p className="mx-auto lg:w-3/5">検索結果：{resultCount}件</p>
        </div>
        {
          // result.slice(offset, offset + perPage) // 表示したいアイテムをsliceで抽出
          result
            .map((val, index) => {
              return(
                <ResultCard
                  id = {val.objectID}
                  schoolName = {val.schoolName}
                  ruleName = {val.ruleName}
                  pref = {val.pref}
                  city = {val.city}
                  key = {index}
                />
              );
          })
        }

        <div className="text-center">
          <div className="inline-block">
            {/* <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={Math.ceil(result.length/perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
                marginPagesDisplayed={2} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
                pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
                onPageChange={handlePageChange} // クリック時のfunction
                containerclassName={'pagination flex items-center justify-center mt-4 overflow-hidden bg-indigo-100 border rounded-lg px-3 py-6'} // ページネーションであるulに着くクラス名
                subContainerclassName={'pages pagination'}
                activeclassName={'active'} // アクティブなページのliに着くクラス名
                previousclassName={'pagination__previous'} // 「<」のliに着けるクラス名
                nextclassName={'pagination__next'} // 「>」のliに着けるクラス名
                disabledclassName={'pagination__disabled'} // 使用不可の「<,>」に着くクラス名
            /> */}
            <ul className="pagination flex items-center justify-center mt-4 overflow-hidden bg-indigo-100 border rounded-lg px-3 py-6">
              <li className={previousClassName}>
                <a tabIndex="0" role="button" onClick={handlePagePrevious} aria-disabled={previousAriaDisabled} aria-label="Previous page" rel="prev">&lt;</a>
              </li>
              {pageLink()}
              <li className={nextClassName}>
                <a tabIndex="0" role="button" onClick={handlePageNext} aria-disabled={nextAriaDisabled} aria-label="Next page" rel="next">&gt;</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <aside className='w-1/5'>
         <AdsCardForList />
      </aside>
    </>
  );
}

function NoResult() {
  return (
    <div className="container px-5 mx-auto">
      <div className="flex items-center w-4/5 mx-auto mb-10">
        <p>該当する検索結果はありません。</p>
      </div>
      <aside className='w-1/5'>
         <AdsCardForList/>
      </aside>
    </div>
  );
}

function SearchResult(props) {
  // console.log("SearchResult(props)実行開始");

  const isSearching = props.isSearching;
  const result = props.searchResultArray;
  // const resultCount = result.length;
  // const searchText = props.searchText;
  const resultCount = props.searchResultCount;
  const resultPageCount = props.searchResultPageCount;
  const resultCurrentPage = props.searchResultCurrentPage;


  
  if(!isSearching && !resultCount) {
    // return <AdsCardForForm />;
    return (
      <>
        {/*<AdsCardForForm />*/}
        <List />
      </>
    );
  }
  if (resultCount === 0 && result[0] === 0) {
    return <NoResult />;
  }

  return <IsResult
    searchResultArray = {result}
    searchResultCount = {resultCount}
    searchResultPageCount = {resultPageCount}
    searchResultCurrentPage = {resultCurrentPage}
    onPagePrev = {props.onPagePrev}
    onPageNext = {props.onPageNext}
    onPageMove = {props.onPageMove}
  />;
}

export default SearchResult;