import {useParams, withRouter} from 'react-router-dom'; 
import { db } from './firebase';
import React, {useState, useEffect} from 'react';
import Loader from "react-loader-spinner";
import { AdsCardForContent } from './AdsCard';
import {useLocation, useHistory, Link} from "react-router-dom";


// export const RuleContent = withRouter(function(props) {
function RuleContent(props) {
  const ruleCollectionName = "kousoku";
  const { ruleId } = useParams();

  const location = useLocation();
  // console.dir(location.state);
  const history = useHistory();


  const [ruleData, setRuleData] = useState({
    schoolName: '',
    ruleName: '',
    ruleContent: '',
  });
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   window.addEventListener('popstate', overridePopstate, false);
  //   return () => window.removeEventListener('popstate', overridePopstate, false);
  // },[])
  // const overridePopstate = () => {
  //   console.log("ぽっぽっぽ");
  //   console.log(location.state);
  //     if(location.state) {
  //       console.log("ぷっしゅ");
  //       history.push("/", {state:location.state});
  //     } else {
  //       window.history.back();
  //     }
  // }

  useEffect(() => {
    let isMounted = true;
    async function GetRuleContentFromDB() {
      setLoading(true);
      const ruleDoc = db.collection(ruleCollectionName).doc(ruleId);

      setIsError(false);

      try {
        const doc = await ruleDoc.get();
        if (doc.exists) {
          const gotData = doc.data();

          setRuleData({
            schoolName: gotData.schoolName,
            ruleName: gotData.ruleName,
            ruleContent: gotData.ruleContent,
          });

        } else {
          // firestoreにdocumentIDの該当がなかった場合は、トップに遷移
          props.history.push("/");
        }
      } catch(error) {
        setIsError(true);
      }
      setLoading(false);
    }
    
    void GetRuleContentFromDB();

    return () => {
      isMounted = false;
    }

  },[props.history, ruleId]);

  useEffect(() => {
    document.title = ruleData.schoolName + " " + ruleData.ruleName + " - 校則DB";
    return () => document.title ="校則DB";
  },[ruleData]);

  if (loading) {
    return (
      <div className="fixed top-1/2 left-1/2">
        <Loader
          type="TailSpin"
          color="#E0E7FF"
          height={80}
          width={80}
          timeout={3000} //3 secs
        />
      </div>
    );
  } else {
    return (
      <section className="text-gray-600 body-font">
        {isError && <div>Something went wrong...</div> /* isErrorがtrueのとき、右辺のdivを表示 */}
        {location.state && <Link to={{pathname: '/', state: location.state}} className="px-5 mx-auto mb-5 xl:w-4/5 underline text-indigo-500 block">←校則一覧に戻る</Link>}
        <div className="container px-5 mx-auto flex flex-wrap xl:w-4/5">
          <div className="md:w-1/5">
            <h2 className="sm:text-3xl text-xl text-gray-900 font-medium title-font mb-2">{ruleData.schoolName}　{ruleData.ruleName}</h2>
             <AdsCardForContent />
          </div>
          <div className="md:w-4/5 md:pl-6">
            <p className="leading-relaxed text-base">
              {ruleData.ruleContent.split('\n').map((str, index) => (
                <React.Fragment key={index}>{str}<br /></React.Fragment>
              ))}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(RuleContent);