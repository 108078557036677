import React from 'react';
import SearchBar from './SearchBar';
import SearchResult from './SearchResult';
import RuleContent from './RuleContent';
import Loader from "react-loader-spinner";
import {NotFound} from './App';
import Donation from './Donation';
// import Donors from "./Donors";

import {
  Switch,
  Route
} from "react-router-dom";

import algoliasearch from 'algoliasearch';
const searchClient = algoliasearch(
  'FLFP8JL5RI', //algolia application ID
  `f8fde6437f6706723266a2eecfce0b5f` //algolia search only key
);
const indexName = 'kousoku';
const index = searchClient.initIndex(indexName);

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchResultArray: [],
      isSearching: false,
      // isObjectId: null,
      searchResultCurrentPage: undefined,
      searchResultCount: undefined,
      searchResultPageCount: undefined,
      loading: false,
    };

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handlePagePrevious = this.handlePagePrevious.bind(this);
    this.handlePageNext = this.handlePageNext.bind(this);
    this.handlePageMove = this.handlePageMove.bind(this);
  }

  // componentWillMount(){
  //   console.log("コンポーネントのマウント前")
  // }
  // componentDidMount(){
  //   console.log("コンポーネントのマウント後")
  // }
  // componentWillReceiveProps(){
  //   console.log("コンポーネントが受け取るpropsが変化")
  // }
  // shouldComponentUpdate(){
  //   console.log("コンポーネントがアップデートされる前1")
  //   return true;
  // }
  // componentWillUpdate(){
  //   console.log("コンポーネントがアップデートされる前2")
  // }
  // componentDidUpdate(){
  //   console.log("コンポーネントがアップデートされた後")
  // }
  // componentWillUnmount(){
  //   console.log("コンポーネントがアンマウントされた後")
  // }

  handleSearchTextChange(searchText) {
    this.setState({
      isSearching: false,
      searchText: searchText
    });
  }

  handleSearchSubmit(searchPage = 0) {
    if(!this.state.searchText) {
      return null;
    }
    this.setState({
      isSearching: true,
      loading: true,
    });
    
    // console.log("loading turned to true.")
    // console.log(this.state.loading);

    index.search(this.state.searchText,{hitsPerPage: 10, page:searchPage}).then(({ hits, nbPages, nbHits, page }) => {
      // console.log("検索しているつもりのページ：" + searchPage);
      // console.log("返ってきた検索対象ページ：" + page);
      // console.log("******"+this.state.loading);

      if (hits.length === 0) {
        this.setState({
          searchResultArray: [0],
          searchResultCurrentPage: page,
          searchResultCount: nbHits,
          searchResultPageCount: nbPages,
        });
      } else {
        const formedResult = hits.map(hit => 
          ({'objectID': hit.objectID, 'schoolName': hit.schoolName, 'ruleName': hit.ruleName, 'pref': hit.pref, 'city': hit.city})
        );
        // console.log(formedResult.map(r=>r.objectID));
        this.setState({
          searchResultArray: formedResult,
          searchResultCurrentPage: page,
          searchResultCount: nbHits,
          searchResultPageCount: nbPages,
        });
      }
      this.setState({
        loading: false,
      });
      // console.log("loading turned to false.");
      // console.log(this.state.loading);

    });
  }

  handlePagePrevious() {
    let nextSearchPage = this.state.searchResultCurrentPage - 1;
    // this.setState({
    //   searchResultCurrentPage: nextSearchPage,
    // });
    // console.log("handlePagePrevious on Search.js");
    // console.log(nextSearchPage);
    this.handleSearchSubmit(nextSearchPage);
  }

  handlePageNext() {
    let nextSearchPage = this.state.searchResultCurrentPage + 1;
    // this.setState((state) => {
    //   return {searchResultCurrentPage: state.searchResultCurrentPage + 1}
    // });
    // console.log("handlePageNext on Search.js");
    // console.log(nextSearchPage);

    this.handleSearchSubmit(nextSearchPage);
  }

  handlePageMove(page) {
    // this.setState(() => {
    //   return {searchResultCurrentPage: page}
    // });
    // console.log("handlePageMove on Search.js: " + page);
    // console.log(this.state.searchResultCurrentPage);

    this.handleSearchSubmit(page);
  }

  render() {
    return (
      <>
        {/*<Router>*/}
          <Switch>
            <Route exact path="/">
              <SearchBar
                searchText = {this.state.searchText}
                onSearchTextChange = {this.handleSearchTextChange}
                onSearchSubmit = {this.handleSearchSubmit}
              />
              {!this.state.loading  ?
              <SearchResult
                isSearching = {this.state.isSearching}
                searchText = {this.state.searchText}
                searchResultArray = {this.state.searchResultArray}
                searchResultCount = {this.state.searchResultCount}
                searchResultPageCount = {this.state.searchResultPageCount}
                searchResultCurrentPage = {this.state.searchResultCurrentPage}
                onPagePrev = {this.handlePagePrevious}
                onPageNext = {this.handlePageNext}
                onPageMove = {this.handlePageMove}
              /> : 
              <div className = "fixed top-1/2 left-1/2">
                <Loader
                  type="TailSpin"
                  color="#E0E7FF"
                  height={80}
                  width={80}
                  timeout={3000} //3 secs
                />
              </div>
              }
            </Route>
            <Route exact path="/rule/:ruleId">
              <SearchBar
                searchText = {this.state.searchText}
                onSearchTextChange = {this.handleSearchTextChange}
                onSearchSubmit = {this.handleSearchSubmit}
              />
              <RuleContent />
            </Route>
            <Route exact path="/donation">
              <Donation />
            </Route>
            {/* <Route exact path="/donors">
              <Donors />
            </Route> */}
            <Route component={NotFound}/>
          </Switch>
        {/*</Router>*/}
      </>
    );
  }
}

export default Search;