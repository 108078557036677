import React, { useEffect } from 'react';

export function AdsCardForForm(props) {
  useEffect(() => {
    if(window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, [])

  return (
    <ins className="adsbygoogle"
      style={{ "display": "block" }}
      data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
      data-ad-slot={process.env.REACT_APP_GOOGLE_AD_SLOT_FORM_BELOW}
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  );
}

export function AdsCardForList(props) {
  useEffect(() => {
    if(window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, [])

  return (
    <ins className="adsbygoogle"
      style={{ "display": "block" }}
      data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
      data-ad-slot={process.env.REACT_APP_GOOGLE_AD_SLOT_RESULT_LIST}
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  );
}

export function AdsCardForContent(props) {
  useEffect(() => {
    if(window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, [])

  return (
    <ins className="adsbygoogle"
      style={{ "display": "block" }}
      data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
      data-ad-slot={process.env.REACT_APP_GOOGLE_AD_SLOT_CONTENT}
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  );
}